import request from "@/utils/axios";

export function repoParams() {
  return request.get("cosmetic/product/repo/param");
}

export function repoList(params) {
  return request.get("cosmetic/product/repo/list", params);
}

export function claimDetail(params) {
  return request.get("cosmetic/product/repo/claim/detail", params);
}

export function repoImgs(params) {
  return request.get("cosmetic/product/repo/product/img", params);
}
