<template>
  <div class="product_claim">
    <el-card style="margin-bottom: 20px; border-radius: 10px; padding: 10px 0;">
      <el-form ref="ruleForm" size="small" :model="form" label-width="80px">
        <el-row>
          <el-col :span="8" class="screen_box">
            <el-form-item label="品类" prop="category">
              <el-select
                v-model="form.category"
                clearable
                placeholder="请选择品类"
                @change="search"
              >
                <el-option
                  v-for="item in categoryList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="timeRange" label="时间范围">
              <el-select
                v-model="form.timeRange"
                clearable
                placeholder="请选择时间范围"
                @change="search"
              >
                <el-option
                  v-for="item in timeRange"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="品牌" prop="productType">
              <el-select
                v-model="form.brandKey"
                multiple
                filterable
                clearable
                collapse-tags
                collapse-tags-tooltip
                placeholder="请选择品牌"
                style="width: 240px"
                @change="search"
              >
                <el-option
                  v-for="item in brandKeyList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="产品名" prop="productName">
              <el-input
                style="width: 240px"
                v-model="form.productName"
                placeholder="请输入产品名，以空格隔开"
                @input="search"
                clearable
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <el-card style="margin-bottom: 20px; border-radius: 10px">
      <div class="table_top_btn">
        <el-button type="primary" size="small" @click="contrast"
          >对比</el-button
        >
      </div>
      <el-table
        ref="multipleTableRef"
        v-loading="loading"
        :data="productList"
        :default-sort="{ prop: 'salesCountSum', order: 'descending' }"
        @filter-change="filterChange"
        @selection-change="handleSelectionChange"
        @sort-change="sortChange"
        style="width: 100%"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column
          align="left"
          prop="productName"
          label="商品名"
          width="320"
        >
          <template #default="{ row }">
            <span
              style="cursor: pointer; color: #51a6f0"
              @click="cellClick(row)"
            >
              {{ row.productName }}
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="category" label="品类" />
        <el-table-column align="center" prop="brandKey" label="品牌" />
        <el-table-column
          align="center"
          prop="priceTier"
          label="价格段"
          column-key="priceTier"
          :filters="listPriceTier"
        />
        <el-table-column
          sortable="custom"
          align="center"
          prop="salesCountSum"
          label="销量"
        />
        <el-table-column
          sortable="custom"
          align="center"
          prop="salesCountAvg"
          label="月均销量"
        />
        <el-table-column
          sortable="custom"
          align="center"
          prop="salesCountGrowth"
          label="月均销量增速"
        />
        <!-- <el-table-column align="center" prop="isNewProduct" label="新品" /> -->
      </el-table>
      <div class="pagination">
        <span>共 {{ count || 0 }} 条</span>
        <el-pagination
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :total="count || 1"
        />
      </div>
    </el-card>
  </div>
</template>

<script setup>
import { ref, reactive, watch, computed } from "vue";
import { useRouter } from "vue-router";
import { repoParams, repoList } from "@/api/productRepo";
import { ElMessage } from "element-plus";

const router = useRouter();
let form = reactive({
  category: "",
  timeRange: [],
  brandKey: [],
  productName: "",
  priceTier: "",
});
let sortList = reactive({
  order: "desc",
  prop: "salesCountSum",
});
const multipleProductId = ref("");
const multipleProductName = ref("");
const brandKeyList = ref([]);
const categoryList = ref([]);
const productList = ref([]);
const timeRange = ref([]);
const listPriceTier = ref([]);
const loading = ref(false);
const page = ref(1);
const pageSize = ref(10);
const count = ref(0);

const search = () => {
  getRepoList();
};

//页面初始化
const initialize = () => {
  const result = localStorage.getItem("PRODUCTREPO");
  if (result) {
    const value = JSON.parse(result);
    form.brandKey = value.brandKey;
    form.category = value.category;
    form.productName = value.productName;
    form.timeRange = value.timeRange;
  }
  getRepoList();
  getpRepoParams();
};

//获取筛选内容
const getpRepoParams = async () => {
  const { data } = await repoParams();
  brandKeyList.value = setFilterFormat(data.listBrandKey);
  categoryList.value = setFilterFormat(data.listCategory);
  timeRange.value = setFilterFormat(data.listTimeRange);
  listPriceTier.value = data.listPriceTier.map((item) => {
    return { text: item, value: item };
  });
  form.timeRange = data.listTimeRange[0];
  // getRepoList();
};
const setFilterFormat = (val) => {
  return val.map((item) => {
    return {
      label: item,
      value: item,
    };
  });
};

//设置获取产品库列表参数
const filterPrams = computed(() => {
  const res = {
    category: form.category,
    brandKey: form.brandKey.join("||"),
    timeRange: form.timeRange,
    productName: form.productName.replace(new RegExp(" ", "g"), "||"),
    priceTier: form.priceTier,
    page: page.value,
    pageSize: pageSize.value,
    orderName: sortList.prop,
    ascDesc: sortList.order,
  };
  return res;
});

//获取产品库列表
const getRepoList = async () => {
  loading.value = true;
  const { data } = await repoList(filterPrams.value);
  productList.value = data.listProductRepo;
  count.value = data.productCount;
  loading.value = false;
};

//分页器方法
const handleCurrentChange = (val) => {
  page.value = val;
  getRepoList();
};

//表格勾选方法
const handleSelectionChange = (val) => {
  const productIds = val.map((item) => item.productId);
  const productNames = val.map((item) => item.productName);
  multipleProductId.value = productIds.join(",");
  multipleProductName.value = productNames.join(",");
};

//表格排序
const sortChange = (params) => {
  sortList.prop = params.prop;
  if (params.order) {
    sortList.order = params.order === "descending" ? "desc" : "asc";
  }
  getRepoList();
};

// //价格段筛选
const filterChange = (filters) => {
  form.priceTier = filters["priceTier"].join("||");
  getRepoList();
};

//进入产品宣称详情
const cellClick = (row) => {
  router.push({
    path: "/productClaim",
    query: {
      category: filterPrams.value.category,
      timeRange: filterPrams.value.timeRange,
      productIds: row.productId,
      productName: row.productName,
    },
  });
};

//对比按钮
const contrast = () => {
  const result = multipleProductId.value.split(",");
  if (result.length <= 1) {
    return ElMessage({
      message: "对比数据不能小于2条",
      type: "warning",
    });
  } else if (result.length > 5) {
    return ElMessage({
      message: "对比数据不能大于5条",
      type: "warning",
    });
  }
  router.push({
    path: "/productClaim",
    query: {
      category: filterPrams.value.category,
      timeRange: filterPrams.value.timeRange,
      productIds: multipleProductId.value,
      isContrast: 1,
      productName: multipleProductName.value,
    },
  });
};

router.afterEach((to, form) => {
  if (to.name !== "ProductClaim" && form.name == "ProductRepo") {
    localStorage.removeItem("PRODUCTREPO");
  }
});

initialize();

watch(
  () => form,
  (val) => {
    localStorage.setItem("PRODUCTREPO", JSON.stringify(val));
  },
  { deep: true }
);
</script>

<style lang="scss" scoped>
.product_claim {
  .screen_box {
    .category {
      width: 364px;
    }

    .classification {
      width: 390px;
    }
  }

  .table_top_btn {
    margin-bottom: 6px;

    button {
      background: linear-gradient(180deg, #4d7ad7 0%, #5e8cec 100%);
    }
  }

  .pagination {
    margin-top: var(--el-card-padding);
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
</style>
<style lang="scss">
.product_claim {
  .pagination {
    li.active.number {
      background-color: #e09a29 !important;
    }
  }
}

.el-table-filter__checkbox-group label.el-checkbox {
  height: unset;
}
</style>
